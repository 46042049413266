import * as rdd from 'react-device-detect'

const isIos = rdd.isIOS
const isAndroid = rdd.isAndroid
const isDesktop = rdd.isDesktop
const isTablet = rdd.isTablet
const isMobile = rdd.isMobileOnly
const deviceName = rdd.mobileModel
const isAndroidTablet = isAndroid && isTablet
const isIpad = isIos && isTablet
const isIphone = isIos && !isTablet
const isAndroidPhone = isAndroid && !isTablet

function getUserDevice() {
  if (isIphone) {
    return 'iphone'
  }
  if (isAndroidPhone) {
    return 'android'
  }
  if (isDesktop) {
    return 'desktop'
  }
  if (isAndroidTablet) {
    return 'androidTablet'
  }
  if (isIpad) {
    return 'ipad'
  }
  return 'other'
}

export default {
  isIos,
  isAndroid,
  isDesktop,
  isTablet,
  isMobile,
  deviceName,
  isAndroidTablet,
  isIpad,
  isIphone,
  isAndroidPhone,
  getUserDevice,
}
