/* eslint-disable unicorn/no-document-cookie */
const COOKIE_DOMAIN =
  typeof window !== 'undefined' && !window.location.href.includes('agebold.com')
    ? ''
    : 'agebold.com'

export function createCookie(
  name: string,
  value: unknown,
  // 10 years ish
  days = 3650
) {
  if (typeof document === 'undefined') {
    return
  }

  let expires = ''
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  expires = `; expires=${date.toGMTString()}`

  document.cookie = `${name}=${value}${expires};path=/;domain=${COOKIE_DOMAIN}`
}

export function createCookieInMinutes(name: string, value: unknown, minutes?: number) {
  if (typeof document === 'undefined') {
    return
  }

  let expires = ''
  if (minutes) {
    const date = new Date()
    date.setTime(date.getTime() + minutes * 60 * 1000) // ) removed
    expires = `; expires=${date.toGMTString()}` // + added
  }

  document.cookie = `${name}=${value}${expires};path=/;domain=${COOKIE_DOMAIN}`
}

export function getCookie(cookieName: string) {
  if (typeof document === 'undefined') {
    return
  }

  const match = document.cookie.match(new RegExp(`${cookieName}=([^;]+)`))
  if (match) return match[1]
}

export function deleteCookie(cookieName: string) {
  if (typeof document === 'undefined') {
    return
  }

  document.cookie = `${cookieName}=;path=/;domain=${COOKIE_DOMAIN};Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
