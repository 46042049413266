import React from 'react'
import Helmet from 'react-helmet'

const TITLE = 'Age Bold'

type Props = {
  canonicalUrl?: string
  children?: React.ReactNode
  description?: string
  preconnect?: string[]
  title?: string
  titleAppend?: string
  titleOnly?: boolean
}

export default function Head({
  canonicalUrl,
  children,
  preconnect,
  titleAppend,
  titleOnly,
  ...props
}: Props) {
  const title = titleAppend ? `${props.title || TITLE} – ${titleAppend}` : props.title || TITLE
  const description =
    props.description ||
    'Bold is your home for personalized, science-backed workouts shown to reduce fall risk. Assess your strength, mobility, and balance with a free 1-minute test.'

  return (
    <Helmet
      {...props}
      defaultTitle={title}
      title={title}
      titleTemplate={
        titleAppend || titleOnly ? null : title && title !== TITLE ? `%s – ${TITLE}` : null
      }>
      {title && <title>{title}</title>}
      {title && <meta name="twitter:title" property="og:title" content={title} />}
      {description && <meta name="description" property="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      <meta
        name="ahrefs-site-verification"
        content="673e9da9ac8fbac92f86e5930202747e4f7990be24b26c4f4036fa2817ab1483"
      />
      <meta name="google-site-verification" content="0UPkb5iqFCnOd9LHIGRMWjTAL5p2CMRtoodJBob-8-8" />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {preconnect?.map((item) => <link rel="preconnect" href={item} crossOrigin key={item} />)}
      {children}
    </Helmet>
  )
}
