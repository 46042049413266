import bcrypt from 'bcryptjs'
import { utcToZonedTime } from 'date-fns-tz'
import _ from 'lodash'
import storage from 'constants/storage'
import * as api from 'libs/api'
import * as cookie from 'libs/cookie'
import * as date from 'libs/date'

export async function associateUserWithEnterprise(data: unknown) {
  try {
    return await api.post('associateWithEnterprise', data)
  } catch (error) {
    return { error }
  }
}

export async function cancelScheduledLesson(lessonId: string) {
  try {
    return await api.put(`scheduledLesson/${lessonId}/cancel`)
  } catch (error) {
    return { error }
  }
}

export async function changePassword(data: unknown) {
  try {
    const accessToken = cookie.getCookie(storage.ACCESS_TOKEN_KEY)
    data.accessToken = accessToken
    return await api.put('changePassword', data)
  } catch (error) {
    return { error }
  }
}

export async function checkIfUserEmailExists(email: string) {
  try {
    return await api.get(`userEmail?email=${encodeURIComponent(email)}`)
  } catch (error) {
    return { error }
  }
}

export async function checkUserHealthPlanAndConvert(data: unknown) {
  try {
    return await api.post('user/checkHealthPlan', data)
  } catch (error) {
    return { error }
  }
}

function hashPassword(password: string) {
  return new Promise((resolve, reject) => {
    bcrypt.hash(password, 10, (error, hash) => {
      if (error) {
        reject(error)
        return
      }
      resolve(hash)
    })
  })
}

export async function completeOnboarding(data: unknown) {
  try {
    if (data.password) {
      data.password = await hashPassword(data.password)
      delete data.passwordConfirm
    }

    return await api.post('completeOnboarding', data)
  } catch (error) {
    return { error }
  }
}

export async function createStripeCustomerForUser(data: unknown) {
  try {
    return await api.post('createStripeCustomer', data)
  } catch (error) {
    return { error }
  }
}

export async function deleteUser() {
  try {
    return await api.deleteApi('user')
  } catch (error) {
    return { error }
  }
}

export async function exchangeMagicTokenForCredentials(magicToken: string) {
  try {
    return await api.post('authenticate/magicToken', { magicToken })
  } catch (error) {
    return { error }
  }
}

export async function getActivityStatsForUser() {
  try {
    return await api.get('activity').then((response) => {
      // Translate the timestamps to the user's timezone so they display correctly on the activity calendar
      if (response.data && response.data.recentUserStats) {
        response.data.recentUserStats.map((activityDateTime) => {
          activityDateTime.date = utcToZonedTime(activityDateTime.date, date.TIMEZONE)
          return activityDateTime
        })
      }

      return response
    })
  } catch (error) {
    return error
  }
}

export async function getEnterpriseByInternalId(internalId: string) {
  try {
    return await api.get(`enterprise?internalId=${internalId}`)
  } catch (error) {
    return { error }
  }
}

export async function getLatestUserProfile() {
  try {
    return await api.get('userProfile/latest')
  } catch (error) {
    return { error }
  }
}

export async function getLiveLessons() {
  try {
    return await api.get('liveClasses')
  } catch (error) {
    return { error }
  }
}

export async function getRecentLessons() {
  try {
    return await api.get('userRecentLessons')
  } catch (error) {
    return { error }
  }
}

export async function getReefOrcaConfirmationCodeAndConvert(data: unknown) {
  try {
    return await api.post('user/getReefOrcaConfirmationCodeAndConvert', data)
  } catch (error) {
    return { error }
  }
}

export async function getScheduledLessons(statuses = ['scheduled']) {
  try {
    return await api.get(`scheduledLessons?statuses=${statuses.join(',')}`)
  } catch (error) {
    return { error }
  }
}

export async function getUser() {
  try {
    return await api.get('user')
  } catch (error) {
    return { error }
  }
}

export async function getUserByEnterpriseInfo(enterpriseId: string, externalId: string) {
  try {
    return await api.get(`enterpriseUser?enterpriseId=${enterpriseId}&externalId=${externalId}`)
  } catch (error) {
    return { error }
  }
}

export async function getUserDashboard() {
  try {
    return await api.get('userDashboard')
  } catch (error) {
    return { error }
  }
}

export async function getUserExerciseProgramsProgress() {
  try {
    return await api.get('user/exercisePrograms/progress')
  } catch (error) {
    return { error }
  }
}

export async function getUserGoal() {
  try {
    return await api.get('userGoal')
  } catch (error) {
    return { error }
  }
}

export async function getUserStatistics() {
  try {
    return await api.get('user/statistics')
  } catch (error) {
    return { error }
  }
}

export async function hasRemindersScheduled() {
  const userGoalResponse = await getUserGoal()

  return (
    userGoalResponse !== null &&
    userGoalResponse?.data?.reminderDays !== null &&
    !_.isEmpty(userGoalResponse?.data?.reminderDays)
  )
}

export async function linkUserWithEnterpriseMember(data: unknown) {
  try {
    return await api.post('linkUserWithEnterpriseMember', data)
  } catch (error) {
    return { error }
  }
}

export async function magicSignIn(data: unknown) {
  try {
    return await api.post('magicLogin', data)
  } catch (error) {
    return { error }
  }
}

export async function oAuthSignUp(data: unknown) {
  try {
    return await api.put('singleSignOn', data)
  } catch (error) {
    return { error }
  }
}

export async function readBlog() {
  try {
    return await api.post('readBlog')
  } catch (error) {
    return { error }
  }
}

export async function registerIpForDownload() {
  const response = await fetch('https://api.ipify.org?format=json')
  const data = await response.json()
  api.post('registerIpForAppDownloadAuthed', {
    ipAddress: data.ip,
  })
}

export async function requestHelp() {
  try {
    return await api.post('help')
  } catch (error) {
    return { error }
  }
}

export async function requestMagicLink(url: string | null = null, lessonId: string | null = null) {
  try {
    return await api.post('magicLinkLesson', {
      url,
      lessonId,
    })
  } catch (error) {
    return { error }
  }
}

export async function resetPassword(data: unknown) {
  try {
    return await api.post('resetPassword', data)
  } catch (error) {
    return { error }
  }
}

export async function saveEvents(data: unknown) {
  try {
    if (!cookie.getCookie(storage.ACCESS_TOKEN_KEY)) return
    return await api.post('event', data)
  } catch (error) {
    return error
  }
}

export async function scheduleFirstLessonReminder(data: unknown) {
  try {
    return await api.post('scheduleFirstLessonReminder', data)
  } catch (error) {
    return { error }
  }
}

export async function scheduleLesson(data: unknown) {
  try {
    return await api.post('scheduleLesson', data)
  } catch (error) {
    return { error }
  }
}

export async function sendReferralInvite(email: string) {
  try {
    return await api.post('inviteUsers', { email })
  } catch (error) {
    return { error }
  }
}

export async function setUserExerciseProgram() {
  try {
    return await api.post('user/exerciseProgram/set')
  } catch (error) {
    return { error }
  }
}

export async function signInUser(data) {
  try {
    return await api.post('sessions', data)
  } catch (error) {
    return { error }
  }
}

export async function signOutUser() {
  try {
    const refreshToken = cookie.getCookie(storage.REFRESH_TOKEN_KEY)
    const accessToken = cookie.getCookie(storage.ACCESS_TOKEN_KEY)
    if (refreshToken) {
      return await api.post('signOut', {
        refreshToken,
        accessToken,
      })
    }
  } catch (error) {
    return { error }
  }
}

export async function signUpUser(data: unknown) {
  try {
    return await api.put('users', data)
  } catch (error) {
    return { error }
  }
}

export async function switchUserExerciseProgram(difficultyFeedback: string) {
  try {
    return await api.put('exerciseProgram/switch', { difficultyFeedback })
  } catch (error) {
    return { error }
  }
}

export async function unsubscribe(id: string) {
  try {
    return await api.put('unsubscribe', { id })
  } catch (error) {
    return { error }
  }
}

export async function updatePassword(data: unknown) {
  try {
    if (data.newPassword) {
      data.newPassword = await hashPassword(data.newPassword)
    }
    return await api.put('updatePassword', data)
  } catch (error) {
    return { error }
  }
}

export async function updatePhoneNumber(phoneNumber: string) {
  try {
    return await api.put('user/phoneNumber', { phoneNumber })
  } catch (error) {
    return { error }
  }
}

export async function updateUser(data: unknown) {
  try {
    return await api.post('users', data)
  } catch (error) {
    return { error }
  }
}

export async function updateUserGoal(properties: unknown) {
  try {
    return await api.post('userGoal/update', { properties })
  } catch (error) {
    return { error }
  }
}

export async function updateUserProfile(properties: unknown) {
  try {
    return await api.post('userProfile/update', { properties })
  } catch (error) {
    return { error }
  }
}

export async function userExerciseProgramProgression(exerciseProgramId: string) {
  try {
    return await api.put('user/exerciseProgram/progression', { exerciseProgramId })
  } catch (error) {
    return { error }
  }
}
