/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs'
import { stringify } from 'flatted'
import _ from 'lodash'
import * as environment from 'libs/environment'
import constants from 'styles/constants.module.scss'

export function toConsole(
  value: { data?: {} | string; message: string } | string,
  color = constants.CYAN
) {
  if (environment.isProduction) return

  const colorParameter = `color: ${color}`

  if (_.isObject(value)) {
    const { data, message } = value

    if (_.isObject(data)) {
      const dataFiltered = _.pickBy(data)

      if (_.isEmpty(dataFiltered)) return

      console.groupCollapsed(`%c${message.toUpperCase()}`, colorParameter)
      console.table(dataFiltered)
      console.log(stringify(dataFiltered, null, 2))
      console.groupEnd()
    } else {
      console.log(`%c${message.toUpperCase()} - ${data}`, colorParameter)
    }
  } else {
    console.log(`%c${value}`, colorParameter)
  }
}

const DATADOG_STATUS_COLORS = {
  debug: constants.PURPLE,
  error: constants.RED,
  info: constants.BLUE,
  warn: constants.ORANGE,
}

const DATADOG_STATUS_ICONS = {
  debug: '🔎',
  error: '🚫',
  info: 'ℹ️',
  warn: '⚠️',
}

export function toDatadog(
  message: string,
  data = {},
  error: Error | null = null,
  status: 'debug' | 'error' | 'info' | 'warn' = 'debug'
) {
  toConsole(
    {
      message: `${DATADOG_STATUS_ICONS[status]} ${status} - ${message}`,
      data,
    },
    DATADOG_STATUS_COLORS[status]
  )
  toConsole(
    {
      message: `🐶 DATADOG LOG - ${status} - ${message}`,
      data,
    },
    constants.PURPLE
  )

  datadogLogs.logger.log(message, data, status, error)
}
