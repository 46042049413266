import { format, startOfWeek, subDays } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import _ from 'lodash'
import LogRocket from 'logrocket'
import storage from 'constants/storage'
import * as cookie from 'libs/cookie'
import * as date from 'libs/date'
import * as enterprise from 'libs/enterprise'
import type { ExerciseProgram } from 'libs/program'
import * as subscriptions from 'libs/subscriptions'

export type User = {
  //activityStatus: 'active'
  allTimeClassesWatched: number
  createdAt: string
  currentExerciseProgram: string
  currentExerciseProgramExternalName: string
  dateOfBirth: string
  email: string
  emailConsent: boolean
  //emailValidationStatus: 'do_not_mail'
  enterpriseMember: {
    //addressId: null
    //caregiverFirstName: null
    //caregiverLastName: null
    //caregiverPhoneNumber: null
    //configuration: null
    //createdAt: null
    //dob: null
    //eligibilityEndDate: null
    //eligibilityStartDate: null
    //email: null
    //emailValidationStatus: null
    //enterprise: {
    //  id: null
    //  name: null
    //  isActive: null
    //  associatedProviders: null
    //  internalId: null
    //  createdAt: null
    //  updatedAt: null
    //  eligibilityCheck: null
    //  productType: null
    //  numEligibleMonths: null
    //}
    //enterpriseId: null
    //externalId: null
    //fallRiskLevel: null
    //firstName: null
    //gender: null
    //groupName: null
    //groupNumber: null
    //healthPlanMemberId: null
    //healthPlanName: null
    //homePhoneNumber: null
    //id: null
    //lastName: null
    //mahc10Score: null
    //marketingDoNotCall: null
    //marketingDoNotEmail: null
    //marketingDoNotMail: null
    //marketingDoNotText: null
    //mcoContractNumber: null
    //middleInitial: null
    //mobilePhoneNumber: null
    //mostRecentFallDate: null
    //originalEligibilityImportId: null
    //phoneNumber: null
    //planBenefitPackageId: null
    //preferredLanguage: null
    //secondaryPhoneNumber: null
    //sourceId: null
    //specialNeedsPlanType: null
    //updatedAt: null
    //validated: null
  }
  exerciseProgramId: string
  firstName: string
  hasPassword: true
  id: string
  isOnboardingCompleted: boolean
  isTestUser: boolean
  lastActiveAt: string
  lastName: string
  loginType: 'email' | 'google' | 'facebook'
  noOfAssesmentCompleted: number
  onboardingCompletedDate: string
  //pauseData: {}
  signUpDate: string
  srcId?: string
  streakSaves: number
  stripeCustomerId: string
  subscription: {}
  updatedAt: string
  userExerciseProgram: {
    completedWeeks: number | null
    createdAt: string
    currentBlockExpectedEndAt: string
    currentBlockIndex: number
    currentBlockStartAt: string
    exerciseProgram: ExerciseProgram
    exerciseProgramId: string
    first_lesson_id: string
    firstLessonId: string
    firstLessonMatchConfidenceScore: number
    firstLessonSkippedAt: Date
    firstLessonWatchedAt: Date
    id: string
    isActive: boolean
    matchConfidenceScore: number
    status: 'not_started'
    updatedAt: string
    userId: string
  }
  userHealthPlan: {
    //createdAt: | null
    //dob: | null
    //effectiveDate: | null
    //email: | null
    //firstName: | null
    //groupId: | null
    //healthPlanMemberId: | null
    //id: | null
    //lastName: | null
    //phoneNumber: | null
    //postalCode: | null
    //source: | null
    //status: | null
    //updatedAt: | null
    //userId: | null
  }
  userType: 'admin' | 'core' | 'enterprise' | 'enterprise_terminated' | 'trainer'
}

export const FACEBOOK_SRC_ID = 'facebook'
// TODO: mv to libs/enterprise
export const MASS_ADVANTAGE_SRC_ID = 'mass-advantage'

const TYPE_ADMIN = 'admin'
//const TYPE_CORE = 'core'
const TYPE_ENTERPRISE = 'enterprise'
export const TYPE_ENTERPRISE_TERMINATED = 'enterprise_terminated'
const TYPE_TRAINER = 'trainer'

function isUser(user: User) {
  if (!user) return false
  return user && user.userType
}

export function hasCurrentSubscription(user: User) {
  return (
    isUser(user) &&
    subscriptions.isPlan(user.subscription?.plan) &&
    !user.subscription?.cancel_at_period_end
  )
}

export function isAdminUser(user: User) {
  return isUser(user) && user.userType === TYPE_ADMIN
}

export function isPainUser(user: User) {
  return isUser(user) && user.userExerciseProgram?.exerciseProgram?.tags?.includes('pain')
}

export function isTrainerUser(user: User) {
  return isUser(user) && user.userType === TYPE_TRAINER
}

export function isTrialUser(user: User) {
  return isUser(user) && user.subscription?.status === 'trialing'
}

export function isEnterpriseUser(user: User) {
  return isUser(user) && user.userType === TYPE_ENTERPRISE
}

export function isEnterpriseTerminatedUser(user: User) {
  return isUser(user) && user.userType === TYPE_ENTERPRISE_TERMINATED
}

export function isEnterpriseCuttlefishUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    _.startsWith(user.enterpriseMember?.enterprise?.internalId, enterprise.CUTTLEFISH_KEY)
  )
}

export function isEnterpriseDemoClinicalUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.DEMO_CLINICAL_KEY
  )
}

export function isEnterpriseGatorUser(user: User) {
  return (
    isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === enterprise.GATOR_KEY
  )
}

export function isEnterpriseGrouperUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.GROUPER_KEY
  )
}

export function isEnterpriseHerringUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.HERRING_KEY
  )
}

export function isEnterpriseHumpbackUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.HUMPBACK_KEY
  )
}

export function isEnterpriseOctopusUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.OCTOPUS_KEY
  )
}

export function isEnterpriseOrcaUser(user: User) {
  return (
    isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === enterprise.ORCA_KEY
  )
}

export function isEnterpriseOtterUser(user: User) {
  return (
    isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === enterprise.OTTER_KEY
  )
}

export function isEnterpriseReefUser(user: User) {
  return (
    isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === enterprise.REEF_KEY
  )
}

export function isEnterpriseReefOrOrcaUser(user: User) {
  return isEnterpriseOrcaUser(user) || isEnterpriseReefUser(user)
}

export function isEnterpriseUrchinUser(user: User) {
  return (
    isEnterpriseUser(user) &&
    user.enterpriseMember?.enterprise?.internalId === enterprise.URCHIN_KEY
  )
}

export function isEnterpriseTrialUser(user: User) {
  return isEnterpriseUser(user) && user.enterpriseMember?.enterprise?.internalId === 'trial'
}

// janky but keep terminated clinical members as clinical during their 30-day free trial window
// to continue same UX, but then they should be treated as fitness after, even though their user type
// will still be terminated after those 30 days, they can either go to Basic or Premium & won't be trialing
export function isEnterpriseClinicalUser(user: User) {
  return (
    isEnterpriseDemoClinicalUser(user) ||
    isEnterpriseGrouperUser(user) ||
    // isEnterpriseHumpbackUser(user) ||
    isEnterpriseOctopusUser(user) ||
    isEnterpriseOtterUser(user) ||
    (isEnterpriseTerminatedUser(user) &&
      isTrialUser(user) &&
      enterprise.isClinical(user?.enterpriseMember?.enterprise?.internalId))
  )
}

export function isEnterpriseFitnessUser(user: User) {
  return (
    isEnterpriseCuttlefishUser(user) ||
    isEnterpriseGatorUser(user) ||
    isEnterpriseHerringUser(user) ||
    isEnterpriseOrcaUser(user) ||
    isEnterpriseReefUser(user) ||
    isEnterpriseUrchinUser(user) ||
    isEnterpriseTrialUser(user)
  )
}

export function isFreeUser(user: User) {
  const isUserAndNotEnterprise = isUser(user) && !isEnterpriseUser(user)
  if (isUserAndNotEnterprise && !user?.subscription) {
    // eslint-disable-next-line no-console
    console.warn(`isFreeUser: no subscription object for user ${user?.id}`)
    return false
  }
  return (
    isUserAndNotEnterprise &&
    (subscriptions.isFreePlan(user?.subscription?.plan) || user?.subscription?.plan == null)
  )
}

function isFreeOrTrialUser(user: User) {
  return isUser(user) && (subscriptions.isFreePlan(user?.subscription?.plan) || isTrialUser(user))
}

export function canChangePlan(user: User) {
  return isFreeOrTrialUser(user)
}

export function canChangeProgram(user: User) {
  return !isFreeUser(user) && !isEnterpriseOctopusUser(user)
}

export function canRefer(user: User) {
  return isUser(user) && !isEnterpriseOctopusUser(user)
}

export function getEnterprisePartnerLogo(user: User) {
  const internalId = user?.enterpriseMember?.enterprise?.internalId
  const srcId = user?.srcId
  if (!internalId && !srcId) return ''
  return enterprise.getLogo(internalId, user)
}

function getPlan(user: User) {
  if (!user || !user.subscription) {
    LogRocket.captureMessage('user.subscription in getPlan')
  }
  return user?.subscription?.plan
}

function getPlanName(user: User) {
  if (!user) return ''
  return `Bold ${subscriptions.getPlanName(getPlan(user))}${isTrialUser(user) ? ' Trial' : ''}`
}

export function getPlanLevel(user: User) {
  return isFreeUser(user) ? getPlanName(user) : 'Bold Premium'
}

export function getEnterprisePartnerName(user: User) {
  return user?.enterpriseMember?.enterprise?.name ?? ''
}

export function getUniqueDaysActiveThisWeek(lessons) {
  // standardize everything to user's timezone
  const timezone = date.TIMEZONE

  const now = new Date()
  const startOfWeekInTargetTZ = utcToZonedTime(startOfWeek(now, { weekStartsOn: 1 }), timezone)
  const sundayThisWeek = subDays(startOfWeekInTargetTZ, 1) // week starts on Monday in date-fns but we start on Sunday
  const lessonsByZonedDate = lessons?.map((item) => {
    const itemDate = new Date(item.createdAt)
    const zonedItemDate = utcToZonedTime(itemDate, timezone)
    return {
      ...item,
      zonedItemDate,
    }
  })
  const lessonsThisWeek = lessonsByZonedDate?.filter((item) => item.zonedItemDate >= sundayThisWeek)
  const uniqueDaysActive = _.uniqBy(lessonsThisWeek, (item) =>
    format(item.zonedItemDate, 'yyyy-MM-dd')
  )

  return uniqueDaysActive
}

export function hasChallenges(user: User) {
  return !isEnterpriseClinicalUser(user)
}

export function hasDashboard(user: User) {
  return !isEnterpriseClinicalUser(user) && !isFreeUser(user)
}

export function hasExplore(user: User) {
  return !isEnterpriseClinicalUser(user) && !isFreeUser(user)
}

export function hasHome(value: string) {
  return cookie.getCookie(storage.HOME_KEY) === value
}

export function hasHomeSpecial() {
  return hasHome(storage.HOME_SPECIAL)
}

export function hasLiveLessons(user: User) {
  return !isFreeUser(user)
}

export function hasPassword(user: User) {
  if (user?.loginType === 'facebook' || user?.loginType === 'google') {
    return false
  }
  return !isEnterpriseOctopusUser(user)
}

export function isBirthdayToday(user: User) {
  const today = new Date()
  const birthday = new Date(user.dateOfBirth.replaceAll('-', '/'))
  // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off

  return birthday.getDate() === today.getDate() && birthday.getMonth() === today.getMonth()
}

export function isOnboardingPaymentRequired(user: User) {
  return [MASS_ADVANTAGE_SRC_ID].includes(user?.srcId)
}
